<template>
  <v-sheet
    :width="width"
    :height="height"
    class="location-list"
  >
    <slot 
      name="cities"
      :toggle="toggleCities"
    />
    <header
      v-if="!toggleCities"
      class="d-flex align-center pa-2"
    >
      <slot 
        name="header" 
      >
        <v-subheader 
          v-if="city!=null"
          class="text-overline primary--text px-0"
        >
          <v-btn
            icon
            class="mx-2"
            @click="selectCity(null)"
          >
            <v-icon>{{ icons.left }}</v-icon>
          </v-btn>
          <h5 
            v-show="!search.toggle"
            class="text-overline primary--text font-weight-black pl-2"
          >
            {{ city.title }}:
          </h5>
        </v-subheader>
      </slot>
      <div 
        v-show="!toggleCities&&search.toggle"
        ref="search"
        class="search-container flex-grow-1 px-0 ml-n1"
      >
        <gmap-autocomplete 
          v-if="layer=='pois'"
          :bounds="bounds"
          class="address-autocomplete d-block flex-grow-1"
          @place_changed="process"
        >
          <template v-slot:default="slotProps">
            <v-autocomplete
              v-model="search.value"
              :disabled="disabled||search.disabled"
              :placeholder="search.placeholder[layer]+' em '+city.title"
              :items="controller.history.options"
              item-value="id"
              item-text="title"
              return-object
              solo
              flat
              hide-details
              hide-no-data
              append-icon=""
              height="48"
              ref="input"
              class="search-field"
              v-on:listeners="slotProps.listeners"
              v-on:attrs="slotProps.attrs"
              @change="process"
            />
          </template>
        </gmap-autocomplete>
        <v-autocomplete
          v-else
          v-model="search.selected"
          :items="searchGeofences"
          :search-input.sync="search.value"
          :disabled="disabled||search.disabled||layer=='customs'"
          :filter="searchFilter"
          :placeholder="search.placeholder[layer]+' em '+city.title"
          solo
          flat
          auto-select-first
          hide-no-data
          hide-details
          return-object
          append-icon=""
          height="48"
          class="search-field py-0"
        >
          <template v-slot:item="data">
            <span class="text-subtitle-2">
              {{ data.item.title }}
            </span>
          </template>
        </v-autocomplete>
      </div>
      <v-btn
        v-show="!disabled"
        icon
        class="ml-auto"
        @click="toggleSearch"
      >
        <v-icon dense>
          {{ search.toggle ? icons.close : icons.search }}
        </v-icon>
      </v-btn>
      <!-- TODO: move history to poi search (autocomplete) -->
      <!-- <v-menu 
        open-delay="250"
        close-delay="500"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="layer=='pois'&&controller.history.options.length>0"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              {{ icons.history }}
            </v-icon>
          </v-btn>
        </template>
        <v-card 
          min-width="240"
          max-width="320"
          class="poi-history"
        >
          <v-list 
            dense
            :disabled="disabled"
            max-height="60vh"
            class="scrollable"
          >
            <v-list-item>
              <v-list-item-icon
                class="ml-1 mr-4 my-2"
              >
                <v-icon>
                  {{ icons.poi }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-overline mb-1">Histórico:</v-list-item-title>
                <v-list-item-subtitle class="text-caption text-wrap font-weight-regular">Polígonos e Pontos de Interesse utilizados anteriormente.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider 
              class="my-2" 
            />
            <v-list-item-group
              v-model="controller.history.selected"
              multiple
              color="primary"
            >
              <v-list-item
                v-for="(item, id) in controller.history.options"
                :key="'poi-history-'+item.id"
                :value="id"
                class="px-4"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action
                    class="mr-4 my-2"
                    @click="select(!active, active ? item.id : item)"
                  >
                    <v-btn 
                      icon
                      :color="active ? 'primary' : 'grey darken-1'"
                    >
                      <v-icon size="20">{{ active ? icons.ok : icons.add }}</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-content
                    class="px-2"
                    @click="select(!active, active ? item.id : item)"
                  >
                    <v-list-item-title 
                      :title="item.title"
                      class="text-subtitle-2"
                    >
                      {{ item.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle 
                      v-if="item.type=='CUS'"
                      class="text-caption"
                    >
                      <span 
                        class="text-caption text--secondary"
                      >Polígono</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle 
                      v-else-if="item.type=='POI'"
                      class="text-caption"
                    >
                      <span 
                        class="text-caption text--secondary"
                      >Raio: {{ item.radius }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu> -->
    </header>
    <v-divider v-show="!toggleCities" class="padded" />
    <v-expand-transition>
      <v-list
        v-if="!toggleCities"
        :max-height="`calc(100% - 64px)`"
        class="overflow-y-auto pt-2 pb-4"
      >
        <div
          v-if="layer=='geofences'"
          class="layer-list zones"
        >
          <div 
            v-for="(zone, z) in list"
            :key="`location-zones-${z}`"
            class="zone-container"
          >
            <!-- <v-divider class="padded" /> -->
            <v-list-group
              no-action
            >
              <template v-slot:activator>
                <v-list-item-action class="mr-4 my-2">
                  <v-hover v-slot="{ hover }">
                    <v-btn 
                      icon
                      :disabled="disabled"
                      :color="zone.selected=='unchecked' ? 'grey darken-1' : 'primary'"
                      @click.stop="select(zone.selected=='unchecked', { zone: zone.id })"
                    >
                      <v-icon dense>
                        {{ icons[zone.selected] }}
                      </v-icon>
                    </v-btn>
                  </v-hover>
                </v-list-item-action>
                
                <v-list-item-content>
                  <v-list-item-title 
                    :class="zone.selected=='unchecked' ? 'grey--text  text--darken-1' : 'primary--text'"
                    class="text-subtitle-2"
                  >{{ zone.title }}</v-list-item-title>
                </v-list-item-content>
              </template>


              <div 
                v-for="g in zone.geofences"
                :key="`geofences-${g.id}`"
                class="geofence-container"
              >
                <v-hover 
                  v-slot="{ hover }"
                >
                  <v-list-item
                    :disabled="disabled"
                    class="pl-7"
                    @click="select(!g.selected, { zone: zone.id, geofence: g.id })"
                  >
                    <v-list-item-action
                      class="mr-4 my-2"
                    >
                      <v-btn 
                        icon
                        :disabled="disabled"
                        :color="g.selected ? 'primary' : 'grey darken-1'"
                      >
                        <v-icon dense>{{ g.selected ? icons.checked : icons.unchecked }}</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title 
                        :class="[(g.selected ? 'primary--text' : 'grey--text  text--darken-1')]"
                        class="text-subtitle-2"
                      >{{ g.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-hover>
                <v-divider inset />
              </div>
            </v-list-group>
          </div>
        </div>
        <div
          v-else-if="layer=='pois'||layer=='customs'"
          class="layer-list pois"
        >
          <div
            v-if="hasPOIs"
            class="pois-list"
          >
            <div 
              v-for="poi in controller.pois"
              :key="`pois-${poi.id}`"
              class="pois-container"
            >
              <!-- <v-divider class="padded" /> -->
              <v-hover 
                v-slot="{ hover }"
              >
                <v-list-item
                  class="poi-item"
                >
                  <v-list-item-icon
                    class="mr-4"
                  >
                    <v-icon color="primary">{{ icons.poi }}</v-icon>
                  </v-list-item-icon>
                  <v-text-field
                    v-if="poi.rename"
                    v-model="poi.title"
                    solo
                    flat
                    hide-details
                    :ref="'poi-field-'+poi.id"
                    class="poi-field px-0 ml-n1"
                    @keydown.enter="update(poi.id)"
                  />
                  <v-list-item-content
                    v-else
                    class="px-2"
                  >
                    <v-list-item-title 
                      :title="poi.title"
                      class="text-subtitle-2"
                      :class="{ 'mt-2 mb-1': poi.type=='POI' }"
                      @click="rename(poi.id)"
                    >
                      {{ poi.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle 
                      v-if="poi.type=='CUS'"
                      class="text-caption mb-1"
                    >
                      <span 
                        class="text-caption text--secondary"
                      >Polígono</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle 
                      v-else-if="poi.type=='POI'"
                      class="text-caption mb-1"
                    >
                      <span 
                        class="text-caption text--secondary"
                      >Raio: </span>
                      <v-menu
                        v-model="poi.slider"
                        offset-y
                        nudge-bottom="8"
                        :close-on-content-click="false"
                        content-class="rounded-xl"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span 
                            v-bind="attrs"
                            v-on="on"
                            class="radius text-caption font-weight-bold"
                            :class="[disabled ? 'disabled' : 'primary--text']"
                          >{{ poi.radius }}m</span>
                        </template>
                        <v-card 
                          min-width="160"
                          class="radius-slider-container pa-2 px-3"
                        >
                          <v-slider
                            v-model="poi.radius"
                            min="250"
                            max="8000"
                            step="250"
                            ticks="always"
                            :thumb-label="false"
                            hide-details
                            color="primary"
                            class="radius-slider ma-0"
                            @change="update(poi.id)"
                          >
                            <!-- <template v-slot:thumb-label="props">
                              {{ props.value }}m
                            </template> -->
                          </v-slider>
                        </v-card>
                      </v-menu>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action
                    v-if="poi.rename"
                  >
                    <v-btn
                      icon
                      color="success"
                      @click="update(poi.id)"
                    >
                      <v-icon>{{ icons.ok }}</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action
                    v-else
                    class="my-2"
                  >
                    <v-menu
                      offset-y
                      left
                      close-delay="250"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="disabled"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          color="grey"
                        >
                          <v-icon>{{ icons.menu }}</v-icon>
                        </v-btn>
                      </template>
                      <v-card 
                        class="layer-list"
                      >
                        <v-list dense>
                          <v-list-item
                            @click="rename(poi.id)"
                          >
                            <v-list-item-title
                              class=""
                            >
                              Renomear
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            @click="select(false, poi.id)"
                          >
                            <v-list-item-title
                              class="error--text"
                            >
                              Remover
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </v-hover>
            </div> 
          </div>
          <div
            v-else-if="layer=='pois'"
            class="pois-empty pa-6 d-flex flex-column align-center justify-center"
          >
            <v-avatar
              size="64"
              color="primary"
              class="mb-6 placeholder-icon outlined"
            >
              <v-icon size="24">
                {{ icons.poi }}
              </v-icon>
            </v-avatar>
            <h4 class="mb-4 primary--text">
              Personalizado
            </h4>
            <p class="text-body-2 text--secondary text-center">
              Aqui você pode definir Pontos de Interesse ou Polígonos para personalizar a área de veiculação do anúncio.
            </p>
            <p class="text-body-2 text--secondary text-center">
              Ponto de Interesse (POI) é uma <b>área circular</b> de até 3km de raio em torno de um endereço. <b>Digite o endereço do ponto de interesse</b> no campo de busca acima para adicionar um POI.
            </p>
            <p class="text-body-2 text--secondary text-center">
              Com Polígono, defina exatamente a área de exibição <b>desenhando sobre o mapa</b>. <b>Clique no botão + Polígono no mapa ao lado</b>, para começar a desenhar.
            </p>
          </div>
        </div>
      </v-list>
    </v-expand-transition>
  </v-sheet>
</template>

<style>

.location-list .v-divider.padded {
    margin: 0 16px;
}
.location-list .v-divider.padded {
    max-width: calc(100% - 32px);
}

.location-list .geofence-container .v-divider--inset:not(.v-divider--vertical) {
    margin-left: 36px;
}
.location-list .geofence-container .v-divider--inset:not(.v-divider--vertical) {
    max-width: calc(100% - 52px);
}

.location-list .poi-field {
  font-size: 0.875rem !important;
  font-weight: 500;
  /* line-height: 2rem; */
  /* letter-spacing: 0.125em !important; */
  /* border-bottom: 1px solid var(--light-border); */
}

.location-list .radius:not(.disabled) {
  border-bottom: 1px dashed;
}

.location-list .disabled {
  cursor: default;
  pointer-events: none;
}

.location-list .placeholder-icon.outlined {
  border: 2px dotted;
  background: transparent !important;
}

.location-list .search-container input {
  font-size: 0.875rem !important;
  font-weight: 400;
  letter-spacing: 0.0125em !important;
}

/*maps autocomplete*/
  .pac-container, .pac-item {
    width: inherit !important;
  }
  .pac-item {
    font-family:RobotoDraft, Roboto,'Helvetica Neue',sans-serif !important;
    color: var(--mobees-black) !important;
    line-height: 40px !important;
    /*font-weight: 800;*/
  }
  .pac-item-query {
    font-family:RobotoDraft, Roboto,'Helvetica Neue',sans-serif !important;
    font-size: 15px;
    font-weight: 500;
  }
  .pac-item:hover {
    background-color: #eeeeee !important;
    transition: background .15s linear;
  }
  .pac-container {
    color: var(--mobees-black) !important;
    background-color: #fafafa;
    /*font-weight: 800;*/
  }
  .pac-icon, .pac-icon-marker {
    background: none !important;
    background-image: none !important;
  }

</style>

<script>
  import { mdiCheckboxMarked, mdiCheckboxBlankOutline, mdiCheckboxIntermediate, mdiMagnify, mdiRadioboxBlank, mdiRadioboxMarked, mdiMapMarkerStar, mdiDotsVertical, mdiCheck, mdiClose, mdiHistory, mdiPlus, mdiChevronRight, mdiChevronLeft } from '@mdi/js';

  export default {
    props: {
      city: {
        type: Object,
        default: () => null
      },
      geofences: {
        type: Object,
        default: () => {}
      },
      selected: {
        type: Array,
        default: () => []
      },
      pois: {
        type: Array,
        default: () => []
      },
      history: {
        type: Array,
        default: () => []
      },
      bounds: {
        type: Object,
        default: () => null
      },
      loading: {
        type: Boolean,
        default: false
      },
      layers: {
        type: Array,
        default: () => ['geofences', 'customs', 'pois']
      },
      layer: {
        type: String,
        default: 'geofences'
      },
      width: {
        type: [String, Number],
        default: 288
      },
      height: {
        type: [String, Number],
        default: '100%'
      },
      disabled: {
        type: Boolean,
        default: false
      },
    },
    
    data: () => ({
      icons: {
        checked: mdiCheckboxMarked,
        unchecked: mdiCheckboxBlankOutline,
        halfchecked: mdiCheckboxIntermediate,
        search: mdiMagnify,
        marked: mdiRadioboxMarked,
        unmarked: mdiRadioboxBlank,
        poi: mdiMapMarkerStar,
        menu: mdiDotsVertical,
        ok: mdiCheck,
        close: mdiClose,
        history: mdiHistory,
        add: mdiPlus,
        right: mdiChevronRight,
        left: mdiChevronLeft,
      },
      search: {
        toggle: false,
        value: null,
        disabled: false,
        placeholder: {
          'zones': 'Buscar bairro/zona',
          'geofences': 'Buscar bairro',
          'pois': 'Buscar endereço',
          'customs': 'Buscar endereço',
        }
      },
      controller: {
        pois: {},
        history: {
          toggle: false,
          selected: [],
          options: {}
        }
      }
    }),

    computed: {
      searchGeofences () {
        return _.orderBy(_.reduce(_.map(_.clone(this.zones), (zone, z) => {
          return {
            text: zone.title,
            value: zone.id,
            id: zone.id,
            title: zone.title,
            geofences: _.map(zone.geofences, g => { 
              return { text: g.title, value: g.id, ...g } 
            }),
          }
        }), (items, zone) => {
          return _.concat(items, [zone], zone.geofences)
        }, []), ['title'], ['asc']);
      },
      list () {
        const layer = this.layer;
        const geofences = this.geofences;
        const zoned = _.map(this.zones, (zone, z) => {
          let selection = _.sortBy(_.filter(this.selected, g => {
            return (_.has(g, 'zone') ? g.zone.id : geofences[g].zone.id)==zone.id;
          }), ['title']);
          if (_.some(selection, g => !_.has(g, 'id'))) selection = _.map(selection, id => _.clone(geofences[id]));
          return {
            selected: selection.length==0 ? 'unchecked' : selection.length==zone.geofences.length ? 'checked' : 'halfchecked',
            // selection,
            geofences: _.sortBy(_.map(zone.geofences, g => {
              return {
                selected: !_.isNil(_.find(selection, ['id', g.id])),
                ...g
              }
            }), ['title']),
            id: zone.id,
            title: zone.title,
          }
        })
        return layer == 'zones'||layer == 'geofences' ? zoned : _.sortBy(_.reduce(zoned, (geofences, zone) => {
          return _.concat(geofences, zone.geofences)
        }, []), ['title'])
      },

      zones () {
        return _.mapValues(_.groupBy(this.geofences, "zone.id"), (zone, z) => {
          const g = _.find(zone, ["zone.id", parseInt(z)]);
          return {
            id: g.zone.id,
            title: g.zone.title,
            geofences: zone
          };
        });
      },

      orderedPois () {
        const list = _.values(this.controller.pois);
        return _.orderBy(list, ['title'], ['asc']);
      },

      hasPOIs () {
        return _.size(this.controller.pois)>0;
      },

      toggleCities () {
        return this.city==null && _.indexOf(this.layers, 'cities')>=0;
      }
    }, 

    watch: {
      'search.selected' (g) {
        if (g!=null) {
          console.log(g);
          if (_.has(g, 'zone')) {
            this.select(true, { zone: g.zone.id, geofence: g.id });
          }else{
            this.select(true, { zone: g.id });
          }
          setTimeout($ => {
            $.search.selected = null;
            $.search.value = '';
          }, 250, this);
        }
      },
      layer: {
        immediate: true,
        handler (layer) {
          this.controller.pois = this.parse(layer);
        }
      },
      pois: {
        immediate: true,
        deep: true,
        handler (pois) {
          this.controller.pois = this.parse(this.layer);
          this.$nextTick(() => {
            const options = this.controller.history.options;
            this.controller.history.selected = _.without(_.map(this.controller.pois, p => {
              console.log(!_.isNil(_.find(options, ['id', p.id])));
              return _.findIndex(options, ['id', p.id])
            }), -1);
          })
        }
      },
      history: {
        immediate: true,
        deep: true,
        handler (history) {
          const options = _.orderBy(history, ['title'], ['asc']);
          this.controller.history.options = options;
          this.$nextTick(() => {
            this.controller.history.selected = _.without(_.map(this.controller.pois, p => {
              console.log(!_.isNil(_.find(options, ['id', p.id])));
              return _.findIndex(options, ['id', p.id])
            }), -1);
          })
        }
      },
    },

    methods: {
      process (location) {
        console.log('location', location);
        if (location===null) return;
        if (_.has(location, 'geometry')) {
          const position = {
            lat: location.geometry.location.lat(),
            lng: location.geometry.location.lng(),
          }
          location = {
            id: Date.now(),
            title: location.name,
            url: null,
            position,
            radius: 1000,
            type: 'POI',
            use: 'REG',
            local_id: null,
            city: null
          }
        }
        const duplicate = _.some(this.pois, p => p.id==location.id||(p.type=='POI'&&_.has(p, 'position')&&!_.isNil(p.position)&&p.position.lat==location.position.lat&&p.position.lng==location.position.lng))
        if (duplicate) {
          return;
        }
        // const city = _.clone(this.city);
        // const poi = {
        //   id: Date.now(),
        //   title: location.name,
        //   url: null,
        //   position,
        //   radius: 1000,
        //   type: 'POI',
        //   use: 'REG',
        //   local_id: null,
        //   city: null
        // }
        this.select(true, location);
        this.$nextTick(() => {
          this.search.value = null;
        })
      },

      discard () {
        const pois = this.controller.pois;
        _.each(pois, poi => {
          poi.slider = false;
          poi.rename = false;
        })
      },

      outsideDiscard () {
        return [document.querySelector('.poi-item')]
      },

      update (poi, prop, value) {
        console.log(poi);
        const pois = this.controller.pois;
        setTimeout(($, poi) => {
          $.controller.pois[poi].slider = false;
          $.controller.pois[poi].rename = false;
        }, 250, this, poi);
        const before = _.find(this.pois, ['id', poi]);
        if (pois[poi].title!=before.title||pois[poi].radius!=before.radius) {
          this.$emit('update', { prop: 'pois', value: _.values(pois) });
        }
      },

      rename (poi) {
        console.log('rename', poi);
        this.controller.pois[poi].rename = true;
        // setTimeout(($, id) => {
          this.$nextTick(() => {
            this.$refs['poi-field-'+poi][0].focus();
            this.$refs['poi-field-'+poi][0].$refs['input'].select()
          })
        // }, 250, this, poi);
      },

      select (add, data) {
        if (this.layer=='pois'||this.layer=='customs') {
          const pois = add ? 
            _.concat(this.pois, [data]) : 
            _.reject(this.pois, ['id', data])
          setTimeout(($, pois) => {
            $.$emit('update', { prop: 'pois', value: pois });
          }, 250, this, pois);
        }else{
          const selected = _.some(this.selected, g => _.isNumber(g)) ? _.map(this.selected, id => this.geofences[id]) : this.selected;
          const geofences = add ? 
            _.isNil(data.geofence) ? 
              _.concat(selected, this.zones[data.zone].geofences) :
              _.concat(selected, _.filter(this.zones[data.zone].geofences, ['id', data.geofence])) :
            _.isNil(data.geofence) ? 
              _.reject(selected, ['zone.id', data.zone]) :
              _.reject(selected, ['id', data.geofence]);
          // console.log(geofences);
          setTimeout(($, geofences) => {
            $.$emit('update', { prop: 'geofences', value: _.map(geofences, g => g.id) });
          }, 250, this, geofences);
        }
      },

      selectCity (city) {
        console.log('select-city', city);
        this.search.toggle = false;
        this.$emit('select-city', city);
      },

      parse (layer) {
        return layer=='pois' ? _.mapValues(_.keyBy(_.clone(this.pois), 'id'), p => {
          return {
            slider: false,
            rename: false,
            ...p
          }
        }) : {}
      },

      toggleSearch () {
        this.search.toggle = !this.search.toggle;
        this.$nextTick(() => {
          if (this.search.toggle) this.$refs['search'].querySelector('input').focus();
        })
      },

      toggleHistory () {
        this.controller.history.toggle = !this.controller.history.toggle;
      },

      searchFilter (item, queryText, itemText) {
        return new RegExp(queryText.normalize("NFD").replace(/\p{Diacritic}/gu, ""), 'gi').test(itemText.normalize("NFD").replace(/\p{Diacritic}/gu, ""));
      }
    },

    mounted () {
    }

  }
</script>